import * as React from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import Layout from "../components/layout";
import Seo from "../components/seo";

const Body = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

const Wrapper = styled.div`
  padding: 0 3rem;
  text-align: center;
`;

const Icon = styled.span`
  font-size: 10rem;
`;

const Title = styled.h1`
  margin-bottom: 3.2rem;
  color: ${({ theme }) => theme.colors.brand};
  font-size: 7.2rem;
  font-family: ${({ theme }) => theme.fontFamily};
  font-weight: ${({ theme }) => theme.fontWeights.black};
`;

const Text = styled.p`
  max-width: 56.2rem;
  margin: 0 auto;
  line-height: 1.5;
  color: ${({ theme }) => theme.colors.white};
  font-size: 2rem;
  font-family: ${({ theme }) => theme.fontFamily};
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};
  a {
    transition: 0.5s all ease;
    color: ${({ theme }) => theme.colors.white};
    text-decoration: none;
    border-bottom: 2px solid ${({ theme }) => theme.colors.white};
    &:hover,
    &:focus {
      color: ${({ theme }) => theme.colors.brand};
      border-bottom: 2px solid ${({ theme }) => theme.colors.brand};
    }
  }
`;

const NotFoundPage = () => {
  return (
    <Layout footerPosition={"absolute"} current="">
      <Seo
        title={"Voxware - 404"}
        description={"Not Found"}
        openGraphImage={"/images/voxware-home-og.png"}
      />
      <Body>
        <Wrapper>
          <Icon>
            <span role="img" aria-label="sad face">
              🙁
            </span>
          </Icon>
          <Title>Whoops</Title>
          <Text>
            Sadly, this page does not exist. Please visit our{" "}
            <Link to="/">homepage</Link> to learn more about multimodal
            technology.
          </Text>
        </Wrapper>
      </Body>
    </Layout>
  );
};

export default NotFoundPage;
